import type { QueryResult } from '@apollo/client';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import type {
  NodePoliciesQuery,
  NodePoliciesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { Optional } from '@aurora/shared-types/community';
import AppContext from '../context/AppContext/AppContext';
import TenantContext from '../context/TenantContext';
import useQueryWithTracing from '../useQueryWithTracing';
import nodePoliciesQuery from './NodePolicies.query.graphql';

const log = getLog(module);

/**
 * Gets node-specific policies for a node.
 *
 * @param module The module
 * @param variables any node policies query variables.
 * @param skip whether to skip the graphql call.
 * @param ssr whether to run the query on the server side render
 * @param useCache whether to use the 'cache-first' fetch policy.  If false, 'cache-and-network' will be used.
 * @param selectedNodeId The id of the node selected in the node picker.
 *
 * @author Jonathan Bridges
 */
export default function useNodePolicies(
  module: NodeModule | string,
  variables: Optional<NodePoliciesQueryVariables, 'id'>,
  skip = false,
  ssr = false,
  useCache = true,
  selectedNodeId = null
): QueryResult<NodePoliciesQuery, NodePoliciesQueryVariables> {
  const tenant = useContext(TenantContext);
  const { contextNode } = useContext(AppContext);
  const modifiedVariables = { id: selectedNodeId ?? contextNode?.id, ...variables };
  const result = useQueryWithTracing<NodePoliciesQuery, NodePoliciesQueryVariables>(
    module,
    nodePoliciesQuery,
    {
      variables: modifiedVariables,
      fetchPolicy: useCache ? 'cache-first' : 'cache-and-network',
      skip: skip || IdConverter.isOptimistic(tenant, modifiedVariables.id) || !modifiedVariables.id,
      ssr
    }
  );
  if (result?.error) {
    log.error(result.error, 'Error getting node policies for %O', modifiedVariables);
  }
  return result;
}
